import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
// components
import Logo from '../../../components/svg/logo';
import NavbarNav from '../../../components/navbarNav';
import Button from '../../../components/button';
import Modal from '../../../components/modal';
import Login from '../../authentication/login';
import VerificationCode from '../../authentication/verificationCode';
import AlertMessage from '../../../components/alertMessage';
import FollowFriendIcon from '../../../components/svg/followFriendIcon';
import AvatarProfileTop from '../../../components/avatarProfileTop';
import Dropdown from '../../../components/dropdown';
import MenuIcon from '../../../components/svg/menuIcon';
import LaunchKyc from '../../authentication/kyc';
import KycIcon from '../../../components/svg/kycIcon';
import Text from '../../../components/text';
// hooks
import useHeader from './hooks/header.hook';
import WarningIcon from '../../../components/svg/warningIcon';

const navbarListLoginAfter = [
  {
    id: '1',
    text: 'Buy / Sell',
    url: '/',
  },
  {
    id: '2',
    text: 'History',
    url: '/history',
  },
  {
    id: '3',
    text: 'Profile',
    url: '/profile',
  },
  {
    id: '4',
    text: 'Support',
    url: '/support',
  },
];
const navbarListLoginBefore = [
  {
    id: '1',
    text: 'Partnership',
    url: '/partnership',
  },
  {
    id: '2',
    text: 'API Documentation',
    url: 'https://document.payonramp.com/',
  },
];
const cryptoDropdown = [
  {
    id: '1',
    text: 'Buy Crypto',
    url: '/buy-crypto',
  },
  {
    id: '2',
    text: 'Sell Crypto',
    url: '/sell-crypto',
  },
];

const Header = () => {
  const {
    isMobileWithTablet,
    isMenu,
    onToggleMenu,
    isOpenModal,
    onOpenLoginModal,
    onCloseModal,
    isVerify,
    onOpenVerify,
    VerifyUserOtpCallback,
    isAccountUnderVerify,
    goToBackLogin,
    onOpenKYC,
  } = useHeader();

  const kyc = useRef(null);

  const { userPhoneNumber } = useSelector((state) => state?.userPersistReducer);
  const { isLoggedIn, isPanAadharLinked } = useSelector((state) => state?.userPersistReducer);
  const { profileDetails, isLoading } = useSelector((state) => state?.userReducer);

  let alertMessage;

  // Get the current location/route
  const location = useLocation();

  // Define an array of routes where you want to hide the header
  const routesToHideHeader = ['/profile'];

  // Check if the current route should hide the header
  const shouldHideHeader = routesToHideHeader.includes(location.pathname);

  let kycStatusMessage = '';
  let IncompletePanSeedingMessage = 'To avoid a 20% TDS deduction on SELL transaction, please ensure your PAN and Aadhar are linked.';

  const kycProfileData = profileDetails?.kycInfo?.kycStatus;

  if (kycProfileData) {
    alertMessage = false;
    const messages = {
      NOT_YET_STARTED:
        'Upload your KYC documents to start buying/selling your crypto',
      BASIC_KYC_PENDING:
        'Your account is under verification. We are trying to verify as soon as possible. Check your Profile for Further Updates',
      BASIC_KYC_REJECTED:
        'Your KYC has been rejected. Please try again by uploading your documents',
      FULL_KYC_PENDING:
        'Your video KYC is under verification. We are trying to verify as soon as possible. Check your Profile for Further Updates',
    };
    kycStatusMessage = messages[kycProfileData];
    if (
      isLoggedIn &&
      (kycProfileData === 'BASIC_KYC_PENDING' ||
        kycProfileData === 'FULL_KYC_PENDING')
    ) {
      alertMessage = true;
    } else {
      alertMessage = false;
    }
  }

  return (
    <>
      <Helmet>
        <title>
          Efficient Fiat and Crypto Payment Gateway Services - PayOnRamp
        </title>
        <meta
          name="description"
          content="Discover seamless payment solutions with PayOnRamp's efficient fiat and crypto payment gateway services. Streamline transactions for your business and cater to both traditional and digital currency needs."
        />
        <link rel="canonical" href="https://payonramp.com/" />
      </Helmet>
      <header className="header-wrapper">
        <div className="container">
          <div className="header-main">
            <nav className="navbar-wrapper">
              <div className="nav-left">
                <div className="logo-wrp">
                  <Link to="/" className="logo-link">
                    <Logo />
                  </Link>
                </div>
              </div>
              {!isMobileWithTablet && (
                <NavComponement
                  isLoginAfter={isLoggedIn}
                  onOpenModal={onOpenLoginModal}
                />
              )}
              {isMobileWithTablet && (
                <>
                  <Button
                    type="button"
                    onClick={onToggleMenu}
                    icon={<MenuIcon close={isMenu} />}
                    linkPrimary
                    className="nav-toggle"
                  />
                </>
              )}
            </nav>
            {isMobileWithTablet && isMenu && (
              <div className="nav-slide">
                <NavComponement
                  isLoginAfter={isLoggedIn}
                  isMobileWithTablet={isMobileWithTablet}
                  onOpenModal={onOpenLoginModal}
                />
              </div>
            )}
          </div>
        </div>
        {alertMessage && !shouldHideHeader && kycStatusMessage && (
          <AlertMessage
            icon={<FollowFriendIcon />}
            message={kycStatusMessage}
          />
        )}
        {isLoggedIn && !isLoading &&
          (kycProfileData !== 'BASIC_KYC_PENDING' || kycProfileData !== 'BASIC_KYC_REJECTED') &&
          (location.pathname === "/" || location.pathname === '/profile') &&
          (!isPanAadharLinked) && (
            <AlertMessage
              icon={<WarningIcon />}
              message={IncompletePanSeedingMessage}
            />
          )}
      </header>
      {isLoggedIn && !shouldHideHeader && !alertMessage && kycStatusMessage && (
        <header className="header-wrapper">
          <div className="container">
            <div className="header-main1">
              <nav className="navbar-wrapper">
                <div className="nav-left">
                  <div className="logo-wrp">
                    <div className="kyc-text">
                      <div className="svg">
                        <KycIcon />
                      </div>
                      <div className="text">
                        <Text type={'PARAGRAPH'} text={kycStatusMessage} />
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="button-kyc"
                  onClick={() => kyc.current.onInitiateKyc()}
                >
                  <u>Upload</u>
                </button>
              </nav>
            </div>
          </div>
        </header>
      )}
      {isOpenModal && (
        <Modal
          open={isOpenModal}
          onClose={isAccountUnderVerify ? '' : onCloseModal}
          small={isAccountUnderVerify}
        >
          {isVerify ? (
            <VerificationCode
              mobileNumber={userPhoneNumber?.mobileNumber}
              goToBack={goToBackLogin}
              onOpenAccountUnderVerify={onOpenKYC}
              VerifyUserOtpCallback={VerifyUserOtpCallback}
            />
          ) : (
            <>
              <Login onOpenVerifyModal={onOpenVerify} />
            </>
          )}
        </Modal>
      )}
      <LaunchKyc ref={kyc} />
    </>
  );
};

export default Header;

const NavComponement = (props) => {
  const { isLoginAfter, onOpenModal, isMobileWithTablet } = props;
  return (
    <>
      <NavbarNav
        lists={isLoginAfter ? navbarListLoginAfter : navbarListLoginBefore}
      />
      <div className="nav-right">
        {isLoginAfter ? (
          !isMobileWithTablet ? (
            <div className="dp-wrapper">
              <button className="dp-heading invisible-btn">
                <AvatarProfileTop />
              </button>
            </div>
          ) : (
            <></>
          )
        ) : (
          <>
            <Dropdown text="Buy/Sell Crypto" list={cryptoDropdown} />
            <Button
              type="button"
              onClick={onOpenModal}
              text="Login/ Register"
              className="nav-right-btn"
            />
          </>
        )}
      </div>
    </>
  );
};
