import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
// components
import Section from '../../../components/section';
import Text from '../../../components/text';
import Flex from '../../../components/flex';
import Image from '../../../components/image';
import ResetIcon from '../../../components/svg/resetIcon';
import Button from '../../../components/button';
import ProgressBar from '../../../components/progressBar';
import KYCDetailCard from '../../../components/kycDetailCard';
import AddBank from '../../home/components/sell/addBank';
import Modal from '../../../components/modal';
import LaunchKyc from '../../authentication/kyc';
import DeletePaymentMethodForm from '../../../components/deletePaymentMethodForm/DeletePaymentMethodForm';
// redux
import { getBankAccountsListFetch } from '../../../redux/withdrawal/reducer';
import {
  delectAcccountFetch,
  getProfileDetailsFetch,
} from '../../../redux/user/reducers';
// assests
import AvatarProfileImg from '../../../assets/img/avatar-profile-img.svg';
import deleteIcon from '../../../assets/img/payment/delete.png';
import ApprovedImg from '../../../assets/img/profile_approved.png';
//utils
import { truncate } from '../../../utils/util';
import DocumentUploadModal from '../../../components/documetUploadForm/documentUploadForm';
import CopyToClipboardCard from '../../../components/copyToClipboardCard';
import LoadingBitcoinFlip from '../../../components/loading/bitcoinFlipLoading';

const ProfileView = () => {
  const dispatch = useDispatch();
  const [openAddBankModal, setOpenAddbankModal] = useState(false);
  const [deletionAccountNumber, setDeletionAccountNumber] = useState(null);
  const [openDeletePaymentModal, setOpenDeletePaymentModal] = useState(false);
  const [openDocumentUploadModal, setOpenDocumentUploadModal] = useState(false);

  const { bankAccountsDetails } = useSelector((state) => state?.withdrawalReducer);
  const { profileDetails, isLoading } = useSelector((state) => state?.userReducer);
  const { isLoggedIn } = useSelector((state) => state.userPersistReducer);
  const kyc = useRef(null);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getProfileDetailsFetch());
      handleGetBanks();
    }
  }, [isLoggedIn]);

  const handleGetBanks = () => {
    dispatch(getBankAccountsListFetch({ payload: { limit: 10, offset: 0 } }));
  };

  const handleDeletePaymentModal = (value, bankId) => {
    setOpenDeletePaymentModal(value);
    setDeletionAccountNumber(bankId);
  };

  const getAccountsCallBack = () => {
    handleGetBanks();
  };

  const deletePaymentMethod = () => {
    dispatch(
      delectAcccountFetch({
        bankId: deletionAccountNumber,
        callback: getAccountsCallBack,
      })
    );
  };

  const handleUpgradeClick = () => {
    setOpenDocumentUploadModal(true);
  };

  const kycProfileData = profileDetails?.kycInfo?.kycStatus;
  let kycStatusMessage = '';

  if (kycProfileData) {
    const messages = {
      BASIC_KYC_PENDING: 'Your account is under verification.',
      BASIC_KYC_REJECTED: 'Your KYC has rejected.',
      BASIC_KYC_SUCCESS: 'Your Basic KYC is done',
      FULL_KYC_PENDING: 'Your Basic KYC is done',
      FULL_KYC_SUCCESS: 'Your Basic KYC is done',
      FULL_KYC_REJECTED: 'Your Basic KYC is done',
    };
    kycStatusMessage = messages[kycProfileData] ?? messages['NOT_YET_STARTED'];
  }

  let maxAmount = profileDetails?.kycInfo?.isFullKycVerified
    ? profileDetails?.kycInfo?.fullKycMonthlyLimit
    : profileDetails?.kycInfo?.basicKycMonthlyLimit;
  let percentage = (
    (profileDetails?.transactionInfo?.monthlyBuyOrderSum / maxAmount || 0) * 100
  ).toFixed(1);

  let Amount = truncate(profileDetails?.transactionInfo?.monthlyBuyOrderSum || 0);

  const ipAddresses = profileDetails?.userInfo?.lastLoginIpAddress;
  const displayIPv4Addresses = (ipAddresses) => {
    if (typeof ipAddresses === 'string') {
      const ipArray = ipAddresses.split(',');
      const ipv4Addresses = ipArray.filter(
        (ip) => ip.includes('.') && !ip.includes(':')
      );
      if (ipv4Addresses.length > 0) {
        return ipv4Addresses[0];
      }
    } else {
      return 'Input is not a string.';
    }
  };

  const extractedIPv4 = displayIPv4Addresses(ipAddresses);

  const renderKYCStatus = () => {
    const kycStatus = profileDetails?.kycInfo?.kycStatus;
    const videoDocsStatus = profileDetails?.kycInfo?.fullKYCDocs?.video;
    const bankStatementDocsStatus = profileDetails?.kycInfo?.fullKYCDocs?.bankStatement;
    const isBasicKycVerified = profileDetails?.kycInfo?.isBasicKycVerified;

    if (kycStatus === 'FULL_KYC_SUCCESS') {
      return (
        <Flex className="profile-kyc-status">
          {
            kycProfileData === "FULL_KYC_SUCCESS" ? (
              <Image src={ApprovedImg} alt="Purchase complete" />
            ) : (
              ''
            )}
          <Text type={'PARAGRAPH'} text={`Your Full KYC is completed!`} />
        </Flex>
      );
    }
    else if (kycStatus === 'BASIC_KYC_SUCCESS') {
      return (
        <Button
          type="button"
          text="Upgrade"
          onClick={handleUpgradeClick}
          disabled={!(videoDocsStatus !== 'REJECTED' || bankStatementDocsStatus !== 'REJECTED')}
        />
      );
    } else if (kycStatus === 'FULL_KYC_PENDING' && (videoDocsStatus === 'REJECTED' || bankStatementDocsStatus === 'REJECTED' || videoDocsStatus === '' || bankStatementDocsStatus === '')) {
      return (
        <>
          <Text type="H6" text="Please Submit Documents Again" className="text-center" />
          <Button
            className="mx-auto"
            type="button"
            text="Upgrade"
            onClick={handleUpgradeClick}
          />
        </>
      );
    }
    else if (kycStatus === 'FULL_KYC_PENDING' && (videoDocsStatus === 'PENDING' || bankStatementDocsStatus === 'PENDING')) {
      return (
        <Text type="H6" text="Your documents are under verification" className="text-center" />
      );
    }
    else if (kycStatus === 'FULL_KYC_REJECTED') {
      return (
        <>
          <Text type={'PARAGRAPH'} text={`Please try again`} />
          <Button
            type="button"
            text="Upgrade"
            onClick={handleUpgradeClick}
            disabled={!isBasicKycVerified}
          />
        </>
      );
    }
    else {
      return (
        <>
          <Text type={'PARAGRAPH'} text={`Finish Basic KYC`} />
          <Button
            type="button"
            text="Upgrade"
            onClick={handleUpgradeClick}
            disabled={!isBasicKycVerified}
          />
        </>
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingBitcoinFlip />
      ) : (
        <Section containerWidth1100 className="pt-0">
          <div className="profile-wrapper">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-6">
                <div className="profile-info-wrp">
                  <Flex flexColumn>
                    <div className="profile-info-img">
                      <Image
                        src={AvatarProfileImg}
                        alt="Rahul Sharma"
                        width="100"
                        height="100"
                      />
                    </div>
                    <>
                      {profileDetails?.userInfo?.fullName ? (
                        <Text
                          type="H3"
                          className="profile-info-title"
                          text={profileDetails?.userInfo?.fullName}
                        />
                      ) : (
                        <Text
                          type="H3"
                          className="profile-info-title"
                          text="Your profile"
                        />
                      )}
                      {profileDetails?.userInfo?.phoneNumber ? (
                        <p className="profile-info-desc">
                          {profileDetails?.userInfo?.phoneNumber}
                        </p>
                      ) : (
                        <></>
                      )}
                      {profileDetails?.userInfo?.lastLoginIpAddress ? (
                        <Text
                          type="H3"
                          className="profile-info-desc-small"
                          text={`IP address: ${extractedIPv4}`}
                        />
                      ) : (
                        <Text
                          type="H3"
                          className="profile-info-desc-small"
                          text="IP Address"
                        />
                      )}
                      {profileDetails?.userInfo?.referralCode ? (
                        <div className="row d-flex align-items-center">
                          <Text
                            type="H6"
                            className="profile-info-referralCode"
                            text={`Referral Code : ${profileDetails?.userInfo?.referralCode}`}
                          />
                          <CopyToClipboardCard title={profileDetails?.userInfo?.referralCode} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  </Flex>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-6">
                <Flex flexColumn>
                  <div className="depositlimits-wrp">
                    <Flex
                      alignItems="center"
                      justifyContent="between"
                      className="depositlimits-first-line"
                    >
                      <Text
                        type="H6"
                        className="depositlimits-title"
                        text="Monthly deposit limits"
                      />
                      <Button
                        type="button"
                        linkPrimary
                        icon={<ResetIcon width={12} height={12} />}
                        onClick={() => dispatch(getProfileDetailsFetch())}
                      />
                    </Flex>
                    <ProgressBar
                      percent={percentage > 100 ? 100 : percentage}
                      balance={Amount}
                      maxAmount={maxAmount}
                    />
                  </div>
                </Flex>
                <Flex flexColumn>
                  <div className="row row-mrl-6">
                    <div className="col-6 col-md-6">
                      <KYCDetailCard
                        active
                        text="Basic KYC"
                        action={
                          <>
                            <Flex className="profile-kyc-status">
                              {kycProfileData === "BASIC_KYC_SUCCESS" ||
                                kycProfileData === "FULL_KYC_SUCCESS" ||
                                kycProfileData === "FULL_KYC_PENDING" ||
                                kycProfileData === "FULL_KYC_REJECTED" ? (
                                <Image src={ApprovedImg} alt="Purchase complete" />
                              ) : (
                                ''
                              )}
                              <Text type={'PARAGRAPH'} text={kycStatusMessage} />
                            </Flex>
                            {profileDetails?.kycInfo?.kycStatus ===
                              'BASIC_KYC_REJECTED' ||
                              profileDetails?.kycInfo?.kycStatus ===
                              'NOT_YET_STARTED' ? (
                              <Button
                                type="button"
                                text="Complete KYC"
                                onClick={() => kyc.current.onInitiateKyc()}
                              />
                            ) : (
                              ''
                            )}
                          </>
                        }
                        detailList={[
                          {
                            title: 'Requirement',
                            list: ['PAN Card', 'Aadhar Card', "Refer to Faq's for Guidelines"],
                          },
                          {
                            title: 'Transaction limits',
                            list: ['20,000/ transaction', '2,00,000/ month'],
                          },
                        ]}
                      />
                    </div>
                    <div className="col-6 col-md-6">
                      <KYCDetailCard
                        text="Full KYC"
                        action={renderKYCStatus()}
                        detailList={[
                          {
                            title: 'Requirement',
                            list: ['Video KYC', 'Bank Statement'],
                          },
                          {
                            title: 'Transaction limits',
                            list: ['50,000/ transaction', '5,00,000/ month'],
                          },
                        ]}
                      />
                    </div>
                  </div>
                </Flex>
                <Flex flexColumn className="mt-4">
                  <Text
                    type="H5"
                    className="depositlimits-title"
                    text="Payment Methods(Max 3 bank Accounts)"
                  />

                  <div className="row row-mrl-6">
                    <div className="col-12 col-md-12">
                      {bankAccountsDetails?.data?.length > 0 &&
                        bankAccountsDetails?.data?.map((data) => (
                          <div
                            className="depositlimits-wrp"
                            key={data.accountNumber}
                          >
                            <div className="row mb-1">
                              <div className="col-11 my-auto ">
                                <div>
                                  <Text
                                    type="H5"
                                    className="depositlimits-title"
                                    text={data.bankName}
                                  />
                                </div>
                                <div>{data.accountNumber}</div>
                              </div>
                              <div className="col-1">
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    handleDeletePaymentModal(true, data.bankId)
                                  }
                                >
                                  <Image src={deleteIcon} alt="deleteIcon" />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      <div className="row">
                        <button
                          disabled={bankAccountsDetails?.data?.length >= 3}
                          className="col-12 d-flex add-bank-button"
                        >
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => setOpenAddbankModal(true, null)}
                            className="my-auto mx-auto font-weight-bold"
                          >
                            + Add Bank Account
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Flex>
              </div>
            </div>
          </div>
          {openAddBankModal &&
            ReactDOM.createPortal(
              <Modal
                open={openAddBankModal}
              >
                <AddBank
                  goToBack={() => setOpenAddbankModal(false)}
                  closeModal={() => setOpenAddbankModal(false)}
                />
              </Modal>,
              document.getElementById('addBank-modal')
            )}
          {openDeletePaymentModal &&
            ReactDOM.createPortal(
              <Modal
                open={openDeletePaymentModal}
                small>
                <DeletePaymentMethodForm
                  deletePaymentMethod={deletePaymentMethod}
                  goToBack={() => handleDeletePaymentModal(false)}
                />
              </Modal>,
              document.getElementById('deletePayment-modal')
            )}
          {openDocumentUploadModal &&
            ReactDOM.createPortal(
              <DocumentUploadModal open={openDocumentUploadModal} onClose={() => {
                setOpenDocumentUploadModal(false)
              }} />,
              document.getElementById('documentUpload-modal')
            )}
        </Section>
      )}
      <LaunchKyc ref={kyc} />
    </>
  );

};

export default ProfileView;
