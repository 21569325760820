import React from "react";
import SocialMedia from "../../../components/socialmedia";
import LogoFooter from "../../../components/svg/logoFooter";
import Text from "../../../components/text";
import NavList from "../../../components/navList";
import { useSelector } from "react-redux";

const Footer = () => {
  const Year = new Date().getFullYear();
  const { isLoggedIn } = useSelector((state) => state?.userPersistReducer);
  return (
    <footer className="footer-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="logo-footer mb-20">
              <LogoFooter />
            </div>
            <SocialMedia />
          </div>
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-4">
                <Text type="H5" text="Company" className="ft-title" />
                <NavList
                  lists={[
                    {
                      id: "2",
                      text: "About Us",
                      url: "/about-us",
                    },
                    {
                      id: "3",
                      text: "Partnership",
                      url: "/partnership",
                    },
                    {
                      id: "4",
                      text: "Support",
                      url: "/support",
                    },
                    {
                      id: "4",
                      text: "API Documentation",
                      url: "https://document.payonramp.com/",
                    },
                    {
                      id: "4",
                      text: "Talk to Us",
                      url: "/becomeAPartner",
                    },
                  ]}
                />
              </div>
              <div className="col-4">
                <Text type="H5" text="Products" className="ft-title" />
                <NavList
                  lists={[
                    {
                      id: "1",
                      text: "Buy/Sell",
                      url: "/",
                    },
                  ]}
                />
              </div>
              <div className="col-4">
                <Text type="H5" text="Resources" className="ft-title" />
                <NavList
                  lists={[
                    {
                      id: "1",
                      text: "Privacy Policy",
                      url: "/privacyPolicy",
                    },
                    {
                      id: "2",
                      text: "Terms and Condition",
                      url: "/termsAndCondations",
                    },
                    {
                      id: "3",
                      text: "User agreement",
                      url: "/userAgreement",
                    },
                    {
                      id: "4",
                      text: "User policy",
                      url: "/userPolicy",
                    },
                    {
                      id: "5",
                      text: "Blogs",
                      url: "https://blogs.payonramp.com/",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="copyright-wrapper">
              Copyright @{Year}
              <Text type="PARAGRAPH" text="" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
